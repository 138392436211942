<template>
  <div class="row">
    <div class="integration--image">
      <img :src="'/dashboard/images/integrations/' + integrationLogo" />
    </div>
    <div class="column">
      <h3 class="integration--title">
        {{ integrationName }}
      </h3>
      <p class="integration--description">
        {{
          useInstallationName(
            integrationDescription,
            globalConfig.installationName
          )
        }}
      </p>
    </div>
    <div class="small-2 column button-wrap">
      <woot-label :title="labelText" :color-scheme="labelColor" />
    </div>
    <div class="small-2 column button-wrap">
      <div v-if="!isPmConnected && integrationId == 'peasy'">
        <woot-button @click="connectToPM(false)">
          Connect
        </woot-button>
      </div>

      <div v-else>
        <router-link
          :to="
            frontendURL(
              `accounts/${accountId}/settings/applications/` + integrationId
            )
          "
        >
          <woot-button icon="settings">
            {{ $t('INTEGRATION_APPS.CONFIGURE') }}
          </woot-button>
        </router-link>

        <span v-if="integrationId == 'peasy'" style="margin-left:5px;">
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            icon="edit"
            @click="connectToPM(true)"
          />
          <!-- <woot-button
            size="small"
            variant="smooth"
            color-scheme="alert"
            icon="delete"
            @click="disconnectFromPM()"
          /> -->
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import WootLabel from 'dashboard/components/ui/Label';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import PMConnectAPI from '../bot/PMConnectAPI';
import AccountAPI from '../../../../api/account';
import Cookies from 'js-cookie';

export default {
  components: {
    WootLabel,
  },
  mixins: [globalConfigMixin],
  props: {
    integrationId: {
      type: [String, Number],
      required: true,
    },
    integrationLogo: {
      type: String,
      default: '',
    },
    integrationName: {
      type: String,
      default: '',
    },
    integrationDescription: {
      type: String,
      default: '',
    },
    integrationEnabled: {
      type: Number,
      default: 0,
    },
    brandProfilesList:{
      type: [Array,Boolean],
      default: false
    },
  },
  data() {
    return {
      isPmConnected: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
    }),
    labelText() {
      return this.integrationEnabled
        ? this.$t('INTEGRATION_APPS.STATUS.ENABLED')
        : this.$t('INTEGRATION_APPS.STATUS.DISABLED');
    },
    labelColor() {
      return this.integrationEnabled ? 'success' : 'secondary';
    },
  },
  watch: {
    $route: async function(to) {
      let path = to.fullPath;
      if (path.endsWith('/settings/applications')) {
        // this.checkPeasyConnection();
        this.checkPeasyConnectionOnMount();
      }
    },
  },
  mounted() {
    // this.checkPeasyConnection();
    this.checkPeasyConnectionOnMount(); // reuse the props insted of calling API muliplle times
  },
  methods: {
    frontendURL,
    connectToPM(connected) {
      if (connected) {
        this.isPmConnected = false;
      } else {
        window.open(
          PMConnectAPI.getBaseURL() + '/ps-connect',
          '_blank',
          'location=yes,height=570,width=520,scrollbars=yes,status=yes'
        );
        let that = this;
        let refreshIntervalId = setInterval(function() {
          if (localStorage.getItem('pm-auth-token')) {
            clearInterval(refreshIntervalId);
            that.checkPeasyConnection();
          }
        }, 1000);
      }
    },
    async checkPeasyConnection() {
      const PM_TOKEN = PMConnectAPI.getPMToken();
      if (PM_TOKEN) {
        const brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
        if (brandProfilesResponse) {
          // token exists and is valid
          this.isPmConnected = true;
        } else {
          // token is expired or invalid
          // localStorage.removeItem('pm-auth-token');
          this.isPmConnected = false;
        }
      } else {
        // token does not exists
        this.isPmConnected = false;
      }
    },
    async checkPeasyConnectionOnMount() {
      const PM_TOKEN = PMConnectAPI.getPMToken();
      if (PM_TOKEN) {
        // const brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
        if (this.brandProfilesList) {
          // token exists and is valid
          this.isPmConnected = true;
        } else {
          // token is expired or invalid
          // localStorage.removeItem('pm-auth-token');
          this.isPmConnected = false;
        }
      } else {
        // token does not exists
        this.isPmConnected = false;
      }
    },
    async disconnectFromPM() {
      const user = Cookies.getJSON('user') || {};
      const payload = {
        email: user.email,
        pm_access_token: '',
      };
      await AccountAPI.updateToken(payload);
    },
  },
};
</script>

