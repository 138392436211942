<template>
  <div class="view-box columns bg-light">
      <!-- :header-title="$t(headerTitle)" -->
    <catalogue-header
      button-route="new"
      :icon="icon"
      :header-title="headerTitle"
      :show-back-button="showBackButton"
      :back-url="backUrl"
    />
    <keep-alive v-if="keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import Cookies from 'js-cookie';
import CatalogueHeader from './CatalogueHeader.vue';
/* eslint no-console: 0 */




export default {
  components: {
    CatalogueHeader

  },
  mixins: [alertMixin],
  props: {
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccount: 'getCurrentAccount',
    }),
  },
  mounted() {
    // if user not allowed to order, then never visit this page
    const user = Cookies.getJSON('user') || {};
    if (user.order?.toString() !== "true") {
      console.log("User not allowed to create order",user);
      this.$router.push(`/app/accounts/${user.account_id}/dashboard`);
    };
    this.orderCreditAlert();
  },
  watch:{
    currentUser:(newuser)=>{
      console.log({newuser});
    }
  },
  methods:{
    orderCreditAlert(){
      console.log(this.currentUser,"currentAccount",this.currentAccount);
      if (this.currentAccount?.low_credit_warning) {
        this.showAlert("Topup now! Your order credit is low")
      }
    },
  },


};
</script>

